import Vue from 'vue';
import VueRouter from 'vue-router';
import DashboardLayout from '../layouts/DashboardLayout';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: 'patients',
    component: DashboardLayout,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '/patients',
        name: 'patients',
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/Patients.vue'),
      },
      {
        path: '/patients/creer',
        name: 'patients.create',
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/PatientCreate.vue'),
      },
      {
        path: '/patients/:id/modifier',
        name: 'patients.edit',
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/PatientEdit.vue'),
      },
      {
        path: 'patients/:id_patient/consultations-de-suivi/creer',
        name: 'consultations-de-suivi.create',
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/ConsultationsDeSuivi/ConsultationsDeSuiviCreate.vue'
          ),
      },
      {
        path: 'patients/:id_patient/consultations-de-suivi/:id/modifier',
        name: 'consultations-de-suivi.edit',
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/ConsultationsDeSuivi/ConsultationsDeSuiviEdit.vue'
          ),
      },
      {
        path: '/patients/:id/facture/:type',
        name: 'facture',
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/ImprimerFacture.vue'
          ),
      },
      {
        path: '/patients/:id/fiche',
        name: 'patients.show',
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/FichePatient.vue'),
      },
      {
        path: '/parametres',
        name: 'parametres',
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/Parametres.vue'),
      },
      {
        path: '/statistiques',
        name: 'statistiques',
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/Statistiques.vue'),
      },
      {
        path: '/recettes',
        name: 'recettes',
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/Recettes.vue'),
      },
      {
        path: '/recettes/creer',
        name: 'recettes.create',
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/Recettes/CreateRecette.vue'
          ),
      },
      {
        path: '/recettes/modifier',
        name: 'recettes.edit',
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/Recettes/EditRecette.vue'
          ),
      },
      {
        path: '/repas/:id',
        name: 'repas',
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/Repas/Repas.vue'),
      },
      {
        path: '/documents',
        name: 'documents',
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/Documents.vue'),
      },
    ],
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      requiresVisitor: true,
    },
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/auth/Login.vue'),
  },

  {
    path: '/logout',
    name: 'logout',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/auth/Logout.vue'),
  },
];

const router = new VueRouter({
  routes,
});

export default router;
