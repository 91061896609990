<template>
  <v-app>
    <v-navigation-drawer
      v-model="drawer"
      :clipped="$vuetify.breakpoint.lgAndUp"
      app
    >
      <v-list nav shaped>
        <v-list-item :to="{ name: 'patients' }">
          <v-list-item-action>
            <v-icon color="accent">mdi-account-group</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Patients</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'statistiques' }">
          <v-list-item-action>
            <v-icon color="secondary">mdi-chart-bar</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Statistiques</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item :to="{ name: 'recettes' }">
          <v-list-item-action>
            <v-icon color="green">mdi-chef-hat</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Recettes</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item :to="{ name: 'documents' }">
          <v-list-item-action>
            <v-icon color="purple">mdi-file-document</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Documents</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item :to="{ name: 'parametres' }">
          <v-list-item-action>
            <v-icon color="indigo">mdi-tune</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Paramètres</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- <v-list-item
          href="https://europe-west1-maurine.cloudfunctions.net/csvJsonReport"
        >
          <v-list-item-action>
            <v-icon color="pink lighten-1">mdi-cloud-download</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Sauvegarder</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->
      </v-list>
    </v-navigation-drawer>

    <v-app-bar clipped-left app color="primary" dark>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title class="ml-0 pl-4">{{ titre }}</v-toolbar-title>
      <!-- <v-text-field
            flat
            solo-inverted
            hide-details
            prepend-inner-icon="mdi-account-search"
            label="Rechercher"
            class="hidden-sm-and-down"
      ></v-text-field>-->
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon @click="$router.push({ name: 'logout' })">
            <v-icon>mdi-logout</v-icon>
          </v-btn>
        </template>
        <span>Déconnexion</span>
      </v-tooltip>
    </v-app-bar>

    <v-main>
      <v-fade-transition mode="out-in" :duration="100">
        <router-view @getTitre="getTitre" v-if="!loading"></router-view>
      </v-fade-transition>
    </v-main>
    <!-- <v-footer color="secondary" app>
         <span class="white--text">&copy; 2019 Maurine DEVARIEUX</span>
    </v-footer>-->
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-app>
</template>

<script lang="ts">
import { db, auth } from "../main";

export default {
  name: "Dashboardlayout",
  data: () => ({
    drawer: null,
    titre: "",
    recherche: "",
    loading: true,
  }),

  mounted() {
    this.getPatients();
  },
  computed: {
    patients() {
      return this.$store.getters.patients;
    },
  },
  methods: {
    getTitre(titre) {
      this.titre = titre;
    },
    async getPatients() {
      this.loading = true;

      // Tester si l'utilisateur n'est pas un patient !
      const testUser = await db
        .collection("patients")
        .where("userId", "==", auth.currentUser.uid)
        .get();
      if (!testUser.empty) {
        await auth.signOut();
        this.$router.push("login");
      }

      // Test passé, c'est OK on affiche les patients
      await this.$store.dispatch("bindPatients");

      this.loading = false;
    },
  },
};
</script>

<style></style>
