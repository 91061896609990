import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import fr from 'vuetify/src/locale/fr.ts';

Vue.use(Vuetify);

export default new Vuetify({
   lang: {
      locales: { fr },
      current: 'fr'
   },
   icons: {
      iconfont: 'mdi'
   },
   theme: {
      themes: {
         light: {
            primary: '#6A1B9A',
            secondary: '#1E88E5',
            accent: '#FF7043'
         }
      }
   }
});
