import Vue from 'vue';
import Vuex from 'vuex';
import { db } from '@/main';
import dayjs from 'dayjs';
import { vuexfireMutations, firestoreAction } from 'vuexfire';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    patients: [],
    recettes: [],
    documents: [],
    categories: [],
    documentsLoaded: false,
  },
  getters: {
    patients(state) {
      return state.patients.map((patient) => {
        patient.date_de_naissance_formatee = dayjs(
          patient.date_de_naissance
        ).format('DD/MM/YYYY');
        patient.fullname = patient.nom.toUpperCase() + ' ' + patient.prenom;
        patient.age = dayjs().diff(dayjs(patient.date_de_naissance), 'years');
        patient.derniereConsultationRendered = patient.derniereConsultation
          ? dayjs(patient.derniereConsultation.toDate()).format('DD/MM/YYYY')
          : '-';
        return patient;
      });
    },
    recettes(state) {
      return state.recettes;
    },
  },
  mutations: {
    ...vuexfireMutations,
    logout(state) {
      state.patients = [];
    },
    setDocumentsLoaded(state) {
      state.documentsLoaded = true;
    },
  },
  actions: {
    // Bind le stream de recettes
    bindRecettes: firestoreAction(({ bindFirestoreRef }) => {
      return bindFirestoreRef('recettes', db.collection('recettes'));
    }),
    bindPatients: firestoreAction(({ bindFirestoreRef }) => {
      return bindFirestoreRef(
        'patients',
        db.collection('patients').orderBy('nom')
      );
    }),
    bindDocuments: firestoreAction(({ bindFirestoreRef }) => {
      return bindFirestoreRef(
        'documents',
        db.collection('documents').orderBy('nom')
      );
    }),
    bindCategories: firestoreAction(({ bindFirestoreRef }) => {
      return bindFirestoreRef(
        'categories',
        db.collection('categories').orderBy('nom')
      );
    }),
  },
  modules: {},
});
