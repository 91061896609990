import Vue from 'vue';
import App from './App.vue';
import router from './router';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/functions';
import vuetify from './plugins/vuetify';
import store from './store';
import './assets/css/app.css';
require('dayjs/locale/fr');
import dayjs from 'dayjs';
dayjs.locale('fr');

Vue.config.productionTip = false;

firebase.initializeApp({
  apiKey: 'AIzaSyB69z-n47gVgLDX0--jLoWWmi9AKGLWoO4',
  authDomain: 'maurine.firebaseapp.com',
  databaseURL: 'https://maurine.firebaseio.com',
  projectId: 'maurine',
  storageBucket: 'maurine.appspot.com',
  messagingSenderId: '911175767067',
  appId: '1:911175767067:web:7621c16adaa0d84d0554be',
});

export const db = firebase.firestore();
export const auth = firebase.auth();
export const storage = firebase.storage();
export const functions = firebase.app().functions('europe-west1');
//  ! Retirer émulateurs
// functions.useEmulator('localhost', 5001);
// db.useEmulator('localhost', 8181);
// auth.useEmulator('http://localhost:9099');

// middleware
router.beforeEach((to, from, next) => {
  const currentUser = firebase.auth().currentUser;
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!currentUser) {
      next({
        name: 'login',
      });
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.requiresVisitor)) {
    if (currentUser) {
      next({
        name: 'patients',
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

let app = '';

firebase.auth().onAuthStateChanged(() => {
  if (!app) {
    app = new Vue({
      router,
      vuetify,
      store,
      render: (h) => h(App),
    }).$mount('#app');
  }
});
